<template>
  <div class="container-page" v-if="Object.keys(card).length">
    <div>
      <p class="title-inner">
        Запись реестра {{ card.statementKind === 1 ? 'заявлений о барьерах' : 'предложений по защитным мерам' }}
      </p>
    </div>
    <div class="table-container mb-16 js-table-arrow">
      <table class="stockTable">
        <tbody>
          <tr>
            <td>Заявитель</td>
            <td>{{ card.fio }}</td>
          </tr>
          <tr>
            <td>Должность</td>
            <td>{{ card.position }}</td>
          </tr>
          <tr>
            <td>Статус</td>
            <td :class="card.status.cssClass">{{ card.status.title }}</td>
          </tr>
          <tr>
            <td>Дата подачи</td>
            <td>{{ $momentFormat(card.created, 'DD.MM.YYYY HH:mm') }}</td>
          </tr>
          <tr>
            <td>Наименование компании</td>
            <td>{{ card.companyName }}</td>
          </tr>
          <tr>
            <td>Отрасль экономики, в которой ведет деятельность компания</td>
            <td>{{ card.companyIndustry?.name }}</td>
          </tr>
          <tr>
            <td>Телефон</td>
            <td>{{ card.phone }}</td>
          </tr>
          <tr>
            <td>Электронная почта</td>
            <td>{{ card.email }}</td>
          </tr>
          <tr>
            <td>Сайт компании</td>
            <td>{{ card.companyUrl }}</td>
          </tr>
          <tr>
            <td>Предмет обращения</td>
            <td>{{ subjectName }}</td>
          </tr>
          <template v-if="card.subject === 1">
            <tr>
              <td>Код ТН ВЭД</td>
              <td>{{ card.tnvedCode }}</td>
            </tr>
          </template>
          <template v-else-if="card.subject === 2">
            <tr>
              <td>Вид услуг</td>
              <td>{{ card.okvedName }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td>Отрасль экономики</td>
              <td>{{ card.industry?.name }}</td>
            </tr>
          </template>
          <tr>
            <td v-if="card.statementKind === 1">Суть барьера</td>
            <td v-else>Предложения по внесению изменений в договор</td>
            <td>{{ card.body }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-group">
      <div class="checkboxInput">
        <input v-model="card.workGroupCheck" type="checkbox" id="workGroupCheck" />
        <label for="workGroupCheck">Рассмотреть на рабочей группе</label>
      </div>
    </div>
    <div class="form-group">
      <div class="form-label">
        Комментарий к изменению статуса
        <small v-if="card.modified"
          >(Добавлен: {{ card.modifiedBy?.fullName }} {{ $momentFormat(card.modified, 'DD.MM.YYYY HH:mm') }})</small
        >
      </div>
      <div>
        <textarea v-model="card.statusComment" rows="3" class="form-control"></textarea>
      </div>
    </div>
    <div class="card-info__row">
      <div>
        <ButtonStock
          class="mr-8"
          title="На рассмотрение"
          v-on:click="changeStatus(2)"
          :disabled="card.statusId !== 1"
        />
        <ButtonStock class="mr-8" title="Принять" v-on:click="changeStatus(3)" :disabled="card.statusId !== 2" />
        <ButtonStock class="mr-8" title="Отклонить" v-on:click="changeStatus(4)" :disabled="card.statusId !== 2" />
      </div>
      <ButtonStock btnType="secondary" title="В реестр" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  import ButtonStock from '../../../components/buttons/ButtonStock';
  import API from '../api/statement';

  export default {
    name: 'StatementCard',
    components: { ButtonStock },
    data() {
      return {
        id: this.$route.params.id,
        card: {},
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.card = response.data;
          });
        }
      },
      changeStatus(statusId) {
        if (statusId === Constants.moderationtStatus.accepted || statusId === Constants.moderationtStatus.rejected) {
          if (!this.card.statusComment?.trim()) {
            Constants.alert.fire(
              'Изменение статуса',
              'При переводе в статус "Принято" или "Отклонено" необходимо написать комментарий.',
              'warning',
            );
            return;
          }
        }
        API.changeStatus({ id: this.card.id, statusId: statusId, comment: this.card.statusComment })
          .then(() => {
            Constants.alert.fire('Изменение статуса', 'Статус записи успешно изменен.', 'success');
            this.loadCard();
          })
          .catch((error) => {
            Constants.alert.fire('Изменение статуса', Constants.commonError, 'error');
            console.error(error?.response?.data);
          });
      },
      close() {
        this.$router.back();
      },
    },
    computed: {
      subjectName() {
        if (this.card.subject === 1) return 'Товары';
        if (this.card.subject === 2) return 'Услуги';
        return 'Инвестиции';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
</style>
